import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import externalLinks from 'utils/externalLinks';
import Layout from 'components/Layout';
import SectionsWrapper from 'components/SectionsWrapper';
import { useLayoutContext } from 'context/LayoutContext';
import ScrollableModal from 'components/ScrollableModal';
import ReadyToTrade from 'components/ReadyToTrade';
import SmallPrint from 'components/SmallPrint';
import Section from 'components/Section';
import Headline from 'components/Headline';
import SEO from 'components/SEO';
import ExternalLink from '../components/ExternalLink';

const HowToFundWrapper = styled(SectionsWrapper)`
  margin-top: 6.5625rem;

  ${ifProp('$showAlert', css`
    margin-top: 9.5625rem;
  `)}

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin-top: 10.0625rem;

    ${ifProp('$showAlert', css`
      margin-top: 13.0625rem;
    `)}
  }

  ${SmallPrint} {
    font-style: normal;
  }
`

const HeadlineSection = styled(Section)`
  ${Headline} {
    text-align: left;
    padding-bottom: 1rem;
  }
`;

const HowToFundSection = styled(Section)`
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
`;

const StyledDiv = styled.div`
  .step-wrapper {
    display: flex;
    font-size: 1.125rem;
    font-weight: 700;

    p {
      font-size: 1.125rem;
      font-weight: 700;
      text-transform: uppercase;
    }

    .step-number {
      color: ${prop('theme.colors.primaryRed')};
      margin-right: 0.3125rem;
    }
  }
`;

const StepsToTransfer = styled.ol`
  font-family: ${prop('theme.fonts.default')};

  &&& {
    a {
      font-size: 0.75rem;
      font-weight: 700;
      color: ${prop('theme.colors.primaryRed')};
      text-decoration: none;

      @media (min-width: ${prop('theme.breakpoints.xs')}) {
        font-size: 0.875rem
      }

      &:hover {
        color: ${prop('theme.colors.gray')};
      }
    }
  }

  li {
    font-size: 0.75rem;
    line-height: 2;

    @media (min-width: ${prop('theme.breakpoints.xs')}) {
      font-size: 0.875rem;
    }
  }
`;

const HowToFundInfo = styled.div`
  margin-bottom: 1rem;

  p {
    margin: 0;
  }

  i {
    font-family: ${prop('theme.fonts.default')};
    font-size: 0.75rem;

    @media (min-width: ${prop('theme.breakpoints.xs')}) {
      font-size: 0.875rem;
    }
  }
`;

const StyledLink = styled.a`
  text-decoration: underline !important;
`;

const StyledTitle = styled.h2`
  font-size: 1.125rem;
  font-weight: 700 !important;
  text-transform: uppercase;
  font-family: ${prop('theme.fonts.default')};

  &.lower-case {
    text-transform: none;
  }

  &.center-text {
    text-align: center;
  }

  &.red-text {
    color: ${prop('theme.colors.primaryRed')};
  }
`;

const StyledH3 = styled.h3`
  font-size: 0.875rem;
  font-weight: 700;
  font-family: ${prop('theme.fonts.default')};
  margin-bottom: 0;

  &.capitalize {
    text-transform: uppercase;
  }

  &.center-text {
    text-align: center;
  }
`;

const CenterTitle = styled.p`
  text-align: center;
  color: ${prop('theme.colors.primaryRed')};
`;

const StyledSpan = styled.span`
  color: ${prop('theme.colors.primaryRed')};
`;

const BoldText = styled.b`
  font-weight: 600;
`;

const StyledListItem = styled.li`
  font-size: 0.875rem;
  font-family: ${prop('theme.fonts.default')};
`;

const RedText = styled.p`
  color: ${prop('theme.colors.primaryRed')};
`;

const SectionTitle = styled.p`
  font-size: 1.125rem;
  text-transform: uppercase;

  .color-red {
    color: ${prop('theme.colors.primaryRed')};
    margin-right: 0.3125rem;
  }

  &&& {
    font-weight: 700;
  }
`;

function HowToFund() {
  const { state: layoutContextState } = useLayoutContext();
  const { showAlert } = layoutContextState;

  return (
    <Layout>
      <SEO title='How To Fund' />
      <HowToFundWrapper noGutters $showAlert={showAlert}>
        <HeadlineSection withPadding>
          <Headline>
            How To Fund Your Account
          </Headline>
          <hr />
        </HeadlineSection>
        <HowToFundSection withPadding>
          <StyledDiv>
            <SectionTitle><span className='color-red'>1.</span>By Wire Transfer</SectionTitle>
            <SmallPrint dark>You can initiate a wire transfer from your bank, online currency broker, or another brokerage firm to fund your account. The account from which you are sending the wire must have the same name as the brokerage account you are funding, as tastytrade does not accept third-party wire transfers. <strong>When using an online currency broker, ensure you send the funds via wire, not ACH, to prevent additional delays or fees.</strong></SmallPrint>
            <SmallPrint dark>For additional information, please visit the <ExternalLink href={'https://support.tastytrade.com/support/s/solutions/articles/43000475189'}>tastytrade Help Center</ExternalLink>. To initiate a wire transfer, please contact your bank, online currency broker, or brokerage firm and provide them with the following information:</SmallPrint>
            <CenterTitle className='medium-font center-text' important>All wire transfers must be made in U.S. Dollars (USD).</CenterTitle>
            <HowToFundInfo>
              <SmallPrint dark bold>Bank Name & Address</SmallPrint>
              <SmallPrint dark>BMO Harris Bank</SmallPrint>
              <SmallPrint dark>111 West Monroe Street</SmallPrint>
              <SmallPrint dark>Chicago, IL 60603</SmallPrint>
            </HowToFundInfo>
            <HowToFundInfo>
              <SmallPrint dark bold>SWIFT Code</SmallPrint>
              <SmallPrint dark>HATRUS44 or HATRUS44XXX</SmallPrint>
              <RedText>
                <SmallPrint important><BoldText>IMPORTANT:</BoldText> for Australian Bank Wires, our banks will require a SWIFT code instead of an ABA number. In the event the Bank Wire form you are using requests an ABA Routing Number, use 071000288</SmallPrint>
              </RedText>
            </HowToFundInfo>
            <HowToFundInfo>
              <SmallPrint bold dark>
                Recipient/Beneficiary Account Number
              </SmallPrint>
              <SmallPrint dark>1619329</SmallPrint>
              <SmallPrint important>
                <BoldText>IMPORTANT:</BoldText> Do not enter your tastytrade account number as the
                receiving bank account number. Make sure you are wiring into
                this bank account number: 1619329
              </SmallPrint>
            </HowToFundInfo>
            <HowToFundInfo>
              <SmallPrint dark bold>Recipient/Beneficiary Name</SmallPrint>
              <SmallPrint dark>tastytrade via Apex Clearing</SmallPrint>
            </HowToFundInfo>
            <HowToFundInfo>
              <SmallPrint dark bold>Recipient/Beneficiary Address</SmallPrint>
              <SmallPrint dark>350 N. St. Paul Street</SmallPrint>
              <SmallPrint dark>Suite 1300</SmallPrint>
              <SmallPrint dark>Dallas, TX 75201</SmallPrint>
            </HowToFundInfo>
            <HowToFundInfo>
              <SmallPrint dark bold>For Further Credit to / Memo / Message to Recipient / Description</SmallPrint>
              <SmallPrint dark>
                Some wiring forms will have a dedicated section for the final recipient/beneficiary information
                (your tastytrade account name and your tastytrade account number), but some might not.
              </SmallPrint>
            </HowToFundInfo>
            <ul>
              <HowToFundInfo>
                <StyledListItem>
                  If the wiring form you are completing has a dedicated section for this information, this is where you should
                  provide your name (as it appears on your tastytrade account), your tastytrade account number, & any other
                  requested information.
                </StyledListItem>
              </HowToFundInfo>
              <HowToFundInfo>
                <StyledListItem>
                  If the wiring form you are completing does not have a dedicated section for this information,
                  there should be a “Memo”, “Message to Recipient”, or some other section that allows you to
                  provide additional information to the recipient of the wire. You should be able to successfully
                  transmit your wire if you provide your tastytrade account number in this field.
                </StyledListItem>
              </HowToFundInfo>
            </ul>
            <SectionTitle><span className='color-red'>2.</span>By Transferring your account from another brokerage firm (ACAT)</SectionTitle>
            <SmallPrint dark>You can fund your tastytrade account by transferring an existing account from another brokerage firm by initiating an ACAT. To do so, please follow these steps:</SmallPrint>
            <StepsToTransfer>
              <li>Open up a like account with tastytrade.</li>
              <li>Fill out and sign the <a href={externalLinks.accountTransferForm} target='_blank' rel='noopener noreferrer'>account transfer form</a>.</li>
              <li>Scan the completed form and email it to <a href='mailto:accounts@tastytrade.com'>accounts@tastytrade.com</a> with the subject ‘ACAT Request’ along with a copy of the most recent statement for your account at the other brokerage firm.</li>
            </StepsToTransfer>
          </StyledDiv>
        </HowToFundSection>
        <ReadyToTrade />
      </HowToFundWrapper>
    </Layout>
  )
}

export default HowToFund;
